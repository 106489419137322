import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Every table consists out of one or more:`}</p>
    <ul>
      <li parentName="ul">{`Headers`}</li>
      <li parentName="ul">{`Rows`}</li>
      <li parentName="ul">{`Columns`}</li>
      <li parentName="ul">{`Cells`}</li>
    </ul>
    <p>{`They can be individually combined in a specific way according to the data to be
displayed.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "standard-table",
      "style": {
        "position": "relative"
      }
    }}>{`Standard table`}</h3>
    <p>{`Use a standard table to display static tabular data.`}</p>
    <h4 {...{
      "id": "large-screens",
      "style": {
        "position": "relative"
      }
    }}>{`Large screens`}</h4>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="350" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D116%253A188&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h4 {...{
      "id": "small-screens",
      "style": {
        "position": "relative"
      }
    }}>{`Small screens`}</h4>
    <p>{`On smaller screens a table will display a horizontal scroll bar if the available
screen size is too small to display the full table content.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="300" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D116%253A190&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h4 {...{
      "id": "emtpy-state",
      "style": {
        "position": "relative"
      }
    }}>{`Emtpy state`}</h4>
    <p>{`An empty state is used when a table has no items to show. It provides an
explanation and conveys meaning to the user by displaying an appropriate
`}<a parentName="p" {...{
        "href": "http://chameleon.mediahuis.be/foundations/iconography/"
      }}>{`Icon`}</a>{` and description.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="350" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FDocumentation-Embeds%3Fnode-id%3D116%253A193&chrome=DOCUMENTATION" allowFullScreen></iframe>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Left align textual data, never use center alignment.`}</li>
      <li parentName="ul">{`Numerical data should always be right-aligned for the sake of readability. The
corresponding headers should follow.`}</li>
      <li parentName="ul">{`Don't use zebra stripes, use column dividers instead.`}</li>
    </ul>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <p><strong parentName="p">{`List`}</strong>{`: To display a simple list of text-only related content, use the
`}<a parentName="p" {...{
        "href": "http://chameleon.mediahuis.be/components/list/code/"
      }}>{`List`}</a>{` component.`}</p>
    <p><strong parentName="p">{`Wide List`}</strong>{`: When you're looking to create a list of related items (that
potentially are actionable), use the
`}<a parentName="p" {...{
        "href": "http://chameleon.mediahuis.be/components/widelist/code/"
      }}>{`Wide List`}</a>{` component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      